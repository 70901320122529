import classNames from 'classnames';
import StyleContextProvider from 'utils/context/StyleContextProvider';
import Link from 'next/link';
import { range } from '@viome/vnxt-nucleus';
import { DataContext } from 'utils/context/DataContextProvider';
import { useContext } from 'react';
import styles from './GlossaryControl.module.scss';

const _ = require('lodash');

const GlossaryControl = () => {
    const { controlTerms, termsForLetter, activeTerms } =
            useContext(DataContext),
        highlightLetter =
            (termsForLetter?.length && termsForLetter[0].letter) ||
            (activeTerms?.length && activeTerms[0].letter),
        highlightWord =
            (termsForLetter?.length && termsForLetter[0].title) ||
            (activeTerms?.length && activeTerms[0].title),
        alphaList = range('A'.charCodeAt(0), 'Z'.charCodeAt(0), 1).map((x) =>
            String.fromCharCode(x),
        ),
        termsSummaryList = (termsList) => {
            const summary = {};
            if (!termsList) {
                return [];
            }
            Array.from(termsList, (obj) => {
                const termLetter = obj.title?.substring(0, 1);
                if (!summary[termLetter]) {
                    summary[termLetter] = 0;
                }
                return summary[termLetter]++;
            });
            return summary;
        },
        summaryList = termsSummaryList(controlTerms),
        pageURL = '/glossary',
        letterIndex = `${pageURL}/`,
        subnavTitle = 'Glossary';

    return (
        <StyleContextProvider styles={styles}>
            <div className={styles.GlossaryControl}>
                {highlightLetter && (
                    <h1 className={styles.GlossaryControl__Breadcrumb}>
                        <Link href={`${pageURL}`}>
                            {subnavTitle}
                        </Link>
                        {highlightLetter && (
                            <>
                                <span> &gt; </span>
                                <Link href={`${letterIndex}${highlightLetter}`}>
                                    {highlightLetter}
                                </Link>
                            </>
                        )}
                        {highlightWord && (
                            <>
                                <span> &gt; </span>
                                <span>{highlightWord}</span>
                            </>
                        )}
                    </h1>
                )}
                <ol className={styles.GlossaryControl__List}>
                    {alphaList.map((letter) => {
                        const key = _.uniqueId(letter),
                            currentLetter = summaryList[letter];
                        return (
                            <li key={key}>
                                {currentLetter && currentLetter > 0 && (
                                    <Link
                                        href={`${pageURL}/${letter}`}
                                        className={classNames(
                                            highlightLetter === letter &&
                                            styles.highlightLetter,
                                        )}
                                    >
                                        {letter}
                                    </Link>
                                )}
                                {!currentLetter && <span>{letter}</span>}
                            </li>
                        );
                    })}
                </ol>
                <hr />
            </div>
        </StyleContextProvider>
    );
};

export default GlossaryControl;
