import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import Router from 'next/router';
import { useDetectOutsideClick } from '@viome/vnxt-nucleus';
import StyleContextProvider from '../../utils/context/StyleContextProvider';
import { useViewport } from '../../utils/context/ViewportContextProvider';
import NavMenu from './NavMenu';
import styles from './Nav.module.scss';

export default function Nav({ data }) {
    const dropdownRef = useRef(null),
        { width } = useViewport(),
        [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false),
        onClick = () => setIsActive(!isActive),
        linkAttr = {
            'data-position': 'nav',
        },
        logo = data?.logo?.fields.file,
        topLinks = data?.topLinks;

    useEffect(() => {
        const bodyEl = document.querySelector(`body`),
            handleRouteChange = () => {
                setIsActive(false);
            };

        if (width < 1200) {
            if (isActive) {
                bodyEl.classList.add('navActive');
            } else {
                bodyEl.classList.remove('navActive');
            }
        } else {
            bodyEl.classList.remove('navActive');
        }

        Router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            Router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [width, isActive]);

    return (
        <StyleContextProvider styles={styles}>
            <header className={classNames(styles.viomeNav)}>
                <nav className={styles.viomeNav__wrapper}>
                    <div className={styles.viomeNav__left}>
                        <Link href="/" {...linkAttr}>
                            <img
                                src={logo.url}
                                alt={logo.title}
                                type={logo.contentType}
                            />
                        </Link>
                    </div>
                    <div className={styles.viomeNav__right}>
                        {topLinks ? <NavMenu topLinks={topLinks} /> : null}
                        <div className={styles.viomeNav__misc}>
                            <button
                                onClick={onClick}
                                className={classNames(
                                    styles.viomeNav__menu,
                                    isActive && styles.active,
                                )}
                                type="button"
                            >
                                <div className={styles.viomeNav__menu__wrapper}>
                                    <div
                                        className={styles.viomeNav__menu__bar}
                                    />
                                    <div
                                        className={styles.viomeNav__menu__bar}
                                    />
                                </div>
                            </button>
                        </div>
                    </div>
                </nav>
            </header>
            <section
                ref={dropdownRef}
                className={classNames(
                    styles.viomeDropdown,
                    isActive && styles.active,
                )}
                id="viomeDropdown_main"
            >
                {topLinks ? <NavMenu topLinks={topLinks} /> : null}
            </section>
        </StyleContextProvider>
    );
}
