import classNames from 'classnames';
import Link from 'next/link';
import { DataContext } from 'utils/context/DataContextProvider';
import { useContext } from 'react';
import styles from './GlossaryTermsIndex.module.scss';
import GlossaryTerm from './GlossaryTerm';

const GlossaryTermsIndex = () => {
    const pageURL = '/glossary',
        letterIndex = `${pageURL}/`,
        { termList, controlTerms, termsForLetter, activeTerms } =
            useContext(DataContext);

    let activeLetter,
        terms = [];

    if (activeTerms?.length > 0) {
        terms = activeTerms;
    } else if (termList?.length > 0) {
        terms = termList;
    } else if (termsForLetter?.length > 0) {
        terms = termsForLetter;
    } else if (controlTerms?.length > 0) {
        terms = controlTerms;
    }

    return (
        <div className={classNames(styles.GlossaryTermsIndex__item)}>
            {terms &&
                terms.length > 0 &&
                terms.map((term) => {
                    const { letter } = term,
                        isFullDisplay = activeTerms?.length > 0,
                        glossaryTerm = (
                            <>
                                {activeLetter !== letter && !isFullDisplay && (
                                    <h2>
                                        <Link href={`${letterIndex}${letter}`}>
                                            {letter}
                                        </Link>
                                    </h2>
                                )}
                                <GlossaryTerm
                                    key={term.slug}
                                    term={term}
                                    full={isFullDisplay}
                                />
                            </>
                        );
                    activeLetter = letter;
                    return glossaryTerm;
                })}
        </div>
    );
};

export default GlossaryTermsIndex;
