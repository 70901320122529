import { useContext } from 'react';
import NavItem from './NavItem';
import { StyleContext } from '../../utils/context/StyleContextProvider';

export default function NavMenu({ topLinks }) {
    const styles = useContext(StyleContext);

    return (
        <div className={styles.viomeNav__topLinks}>
            <ul className={styles.viomeNav__main}>
                {topLinks.map((topLink) => {
                    const linkData = topLink?.fields || topLink,
                        nestedLinks =
                            linkData?.dropdownLinks?.items ||
                            linkData?.dropdownLinks;

                    return (
                        <NavItem
                            linkData={linkData}
                            nestedLinks={nestedLinks}
                            key={linkData.title}
                        />
                    );
                })}
            </ul>
        </div>
    );
}
