import { useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import classNames from 'classnames';
import { ENVIRONMENT } from 'utils/constants';
import PromoContextProvider from 'utils/context/PromoContextProvider';
import { cBoolean, checkSchedule } from '@viome/vnxt-nucleus';
import { bannerContext, modalContext } from 'utils/context/DOMContextProvider';
import Nav from 'componentsbase/Nav/Nav';
import Footer from 'componentsbase/Footer/Footer';
import Metadata from './Metadata';
import PreviewBar from './PreviewBar/PreviewBar';
import BottomWrapper from './BottomWrapper/BottomWrapper';

export default function Layout({
    className,
    children,
    page,
    masterControl,
    isLegacy,
    pageNav = () => {},
}) {
    const mainClass = className || 'viome',
        stickyBanner = page?.stickyBanner || masterControl?.stickyBanner,
        shell = page?.shell,
        hideNav = shell === 'Only Footer' || shell === 'Hide',
        hideFooter = shell === 'Hide',
        compactNav = shell === 'Compact',
        compactFooter = shell === 'Compact' || shell === 'Only Footer',
        hideProducts = masterControl?.hideProducts?.items || [],
        hidePromos = cBoolean(page.hidePromos),
        stickyPromo = cBoolean(page.stickyPromo),
        disablePopup = cBoolean(page.disablePopup),
        discountCode = page?.discountCode,
        promoStartDate = page?.promoStartDate,
        promoEndDate = page?.promoEndDate,
        discountValue = page?.discount,
        references = page?.references,
        determineStickyNav = () => {
            if (page?.isStickyNav !== undefined && page?.isStickyNav !== null) {
                return page?.isStickyNav;
            }
            return masterControl?.isStickyNav;
        },
        isStickyNav = determineStickyNav(),
        slug = page?.slug,
        [bannerActive, setActive] = useState(true),
        [banner, setBanner] = useState(null),
        [modal, setModal] = useState(null),
        handleCloseBanner = () => {
            setActive(false);
        },
        CookieConsent = dynamic(import('./CookieConsent/CookieConsent'), {
            ssr: false,
        }),
        StickyBannerComponent = dynamic(import('./StickyBanner/StickyBanner'), {
            ssr: false,
        }),
        CTABannerComponent = dynamic(import('./CTABanner/CTABanner'), {
            ssr: false,
        }),
        isPromoActiveBySchedule = checkSchedule(promoStartDate, promoEndDate);

    return (
        <PromoContextProvider
            data={{
                hideProducts: hideProducts.map((product) => product.sku),
                hidePromos,
                disablePopup,
                // Ability to override the default product level salesMessage
                // with whats in the StickyBanner, stickyPromo has to be set as true
                salesMessage: stickyPromo && stickyBanner?.fields.body,
                // Ability to auto attach discount code to all CTAs on the page
                discountCode,
                // Ability to update all pricing on the page to reflect discounted value
                discountValue,
                isPromoActiveBySchedule,
                productSelector:
                    (page.settings && page.settings['product-selector']) ||
                    null,
            }}
        >
            <Metadata page={page} masterControl={masterControl} />
            {stickyBanner && (
                <StickyBannerComponent
                    stickyType="Top"
                    stickyBanner={stickyBanner}
                    handleCloseBanner={handleCloseBanner}
                    bannerActive={bannerActive}
                    isPromoActiveBySchedule={isPromoActiveBySchedule}
                />
            )}
            {!hideNav && (
                <Nav
                    stickyBanner={
                        bannerActive && stickyBanner && isPromoActiveBySchedule
                    }
                    data={masterControl.navigationMenu}
                    isStickyNav={isStickyNav}
                    compactNav={compactNav}
                    shell={shell}
                />
            )}
            {pageNav(bannerActive && stickyBanner)}
            <main className={mainClass} role="main">
                <modalContext.Provider
                    value={useMemo(() => ({ modal, setModal }), [])}
                >
                    {modal}
                    <bannerContext.Provider
                        value={useMemo(() => ({ banner, setBanner }), [])}
                    >
                        {banner && (
                            <CTABannerComponent>{banner}</CTABannerComponent>
                        )}
                        <section
                            className={classNames(
                                isLegacy === false ? 'container' : 'wrapper',
                            )}
                            id={slug}
                        >
                            {children}
                        </section>
                    </bannerContext.Provider>
                </modalContext.Provider>

                <BottomWrapper>
                    <CookieConsent />
                </BottomWrapper>
            </main>
            {!hideFooter && (
                <Footer
                    data={masterControl?.siteFooter}
                    compactFooter={compactFooter}
                    references={references}
                />
            )}

            {ENVIRONMENT === 'preview' && <PreviewBar />}
        </PromoContextProvider>
    );
}
