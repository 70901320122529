import Link from 'next/link';
import classNames from 'classnames';
import styles from './Publication.module.scss';

export default function NewsArticle({ data }) {
    const { name, url, publisher, publishedDate } = data;

    return (
        <div className={classNames(styles.publication, 'flex')}>
            <div className={classNames('flex gap-4')}>
                <svg
                    width="20"
                    height="28"
                    viewBox="0 0 20 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.8561 16.7762C13.862 18.4981 11.6603 19.088 9.93843 18.0939C8.21658 17.0998 7.62663 14.8981 8.62074 13.1762L9.20788 12.1593L10.2471 12.7593L9.65997 13.7762C8.99723 14.9241 9.39053 16.3919 10.5384 17.0547C11.6863 17.7174 13.1542 17.3241 13.8169 16.1762L16.8169 10.98C17.4796 9.83214 17.0863 8.36433 15.9384 7.70158C14.7905 7.03884 13.3227 7.43214 12.66 8.58004L12.2471 9.29515L11.2079 8.69515L11.6207 7.98004C12.6149 6.25819 14.8166 5.66824 16.5384 6.66235C18.2603 7.65647 18.8502 9.85819 17.8561 11.58L14.8561 16.7762ZM5.64502 10.8177C6.63913 9.09581 8.84086 8.50586 10.5627 9.49997C12.2846 10.4941 12.8745 12.6958 11.8804 14.4177L11.3323 15.367L10.2931 14.767L10.8412 13.8177C11.5039 12.6698 11.1106 11.2019 9.96271 10.5392C8.81481 9.87646 7.34699 10.2698 6.68425 11.4177L3.68425 16.6138C3.02151 17.7617 3.41481 19.2295 4.56271 19.8923C5.71061 20.555 7.17843 20.1617 7.84117 19.0138L8.29306 18.2311L9.33229 18.8311L8.8804 19.6138C7.88629 21.3357 5.68456 21.9256 3.96271 20.9315C2.24086 19.9374 1.65091 17.7357 2.64502 16.0138L5.64502 10.8177Z"
                        fill="black"
                    />
                </svg>
                <div itemScope itemType="https://schema.org/ScholarlyArticle">
                    <Link href={url} target="_blank">
                        <p className={styles.name} itemProp="name">
                            {name}
                        </p>
                    </Link>
                    <div
                        className={styles.publisher}
                        itemProp="isPartOf"
                        itemScope
                        itemType="https://schema.org/Periodical"
                    >
                        <p
                            className={styles.publisher__name}
                            itemProp="publisher"
                        >
                            {`${publisher}${/\d$/.test(publisher) ? ',' : '.'} `}
                        </p>
                        <p className={classNames(styles.publisher__date)}>
                            <time
                                dateTime={publishedDate}
                                itemProp="datePublished"
                            >
                                {new Date(publishedDate)
                                    .toLocaleString('en-US', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                        timeZone: 'UTC',
                                    })
                                    .replace('1,', '')}
                            </time>
                            .
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
