import classNames from 'classnames';
import Link from 'next/link';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { parseURL } from '@viome/vnxt-nucleus';
import StyleContextProvider from 'utils/context/StyleContextProvider';
import styles from './Footer.module.scss';

export default function Footer({ data }) {
    const logo = data?.logo,
        bottomLinks = data?.bottomLinks.items;

    return (
        <StyleContextProvider styles={styles}>
            <footer className={styles.viome__footer}>
                <div className={styles.footerWrapper}>
                    <div className={styles.footerTop}>
                        <Link href="/">
                            <img
                                src={logo.url}
                                alt={logo.title}
                                type={logo.contentType}
                                loading="lazy"
                            />
                        </Link>
                    </div>

                    <div className={styles.footerMiddle}>
                        <div className={styles.socialSection}>
                            <a
                                className={classNames(
                                    styles.footerLink,
                                    styles['footerLink--fb'],
                                )}
                                href={data.facebook}
                            >
                                <img
                                    src="https://images.ctfassets.net/qk4l4jfatr3e/EjiAEnWI8dpegXpUzrkC5/0b509578f6f32ff101a388400d1042b3/facebook-icon.svg"
                                    alt="Facebook"
                                    type="image/svg+xml"
                                    loading="lazy"
                                />
                                Facebook
                            </a>
                            <a
                                className={classNames(
                                    styles.footerLink,
                                    styles['footerLink--tw'],
                                )}
                                href={data.twitter}
                            >
                                <img
                                    src="https://images.ctfassets.net/qk4l4jfatr3e/3EUmH2EuqO2UGo3Z6qTi3f/67fc2ab1ae58d2f7cbea38bb72685a8f/x-icon.svg"
                                    alt="X"
                                    type="image/svg+xml"
                                    loading="lazy"
                                />
                                X
                            </a>
                            <a
                                className={classNames(
                                    styles.footerLink,
                                    styles['footerLink--ig'],
                                )}
                                href={data.instagram}
                            >
                                <img
                                    src="https://images.ctfassets.net/qk4l4jfatr3e/3J3Sb98vME6THkJja8dPUV/5c4d097e044829f98ec602fb308f983b/instagram-icon.svg"
                                    alt="Instagram"
                                    type="image/svg+xml"
                                    loading="lazy"
                                />
                                Instagram
                            </a>
                            <a
                                className={classNames(
                                    styles.footerLink,
                                    styles['footerLink--yt'],
                                )}
                                href={data.youtube}
                            >
                                <img
                                    src="https://images.ctfassets.net/qk4l4jfatr3e/72jJQsaqRJ3T3r7hXl9yEV/22507c0d85eafc66500b0e949aedcf9e/youtube-icon.svg"
                                    alt="YouTube"
                                    type="image/svg+xml"
                                    loading="lazy"
                                />
                                YouTube
                            </a>
                            <a
                                className={classNames(
                                    styles.footerLink,
                                    styles['footerLink--v'],
                                )}
                                href={data.vimeo}
                            >
                                <img
                                    src="https://images.ctfassets.net/qk4l4jfatr3e/DC0pPXytk2F0GqUZZo4B2/56b691862cde88cd963be72b25724eee/Vimeo-Icon-01.svg"
                                    alt="Vimeo"
                                    type="image/svg+xml"
                                    loading="lazy"
                                />
                                vimeo
                            </a>
                        </div>
                    </div>
                    <hr />
                    <div className={styles.footerText}>
                        {documentToReactComponents(data.footerText.json)}
                    </div>
                    <div className={styles.footerBottom}>
                        <p className={styles.footerCopyright}>
                            {new Date().getFullYear()} Viome Life Sciences, Inc.
                        </p>
                        <div className={styles.bottomLinks}>
                            {bottomLinks && (
                                <>
                                    {bottomLinks.map((bottomLink) => (
                                        <Link
                                            href={parseURL(bottomLink.url)}
                                            key={bottomLink.title}
                                            className={styles.footerLink}
                                        >
                                            {bottomLink.title}
                                        </Link>
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </footer>
        </StyleContextProvider>
    );
}
