import AboutProduct from 'containers/Sections/AboutProduct';
import Basic from 'containers/Sections/Basic';
import Benefits from 'containers/Sections/Benefits';
import CLIAPrivacy from 'containers/Sections/CLIAPrivacy';
import Terms from 'containers/Sections/Terms';
import FAQs from 'containers/Sections/FAQs';

import hero from './hero';

export default function BasicComponent(sectionClass) {
    let basicComponent;

    if (!sectionClass) {
        return null;
    }
    if (sectionClass.indexOf('hero') > -1) {
        basicComponent = hero(sectionClass);
    } else if (
        sectionClass.indexOf('about-product') > -1 ||
        sectionClass.indexOf('aboutProduct') > -1
    ) {
        basicComponent = AboutProduct;
    } else if (
        sectionClass.indexOf('basic ') > -1 ||
        sectionClass === 'basic'
    ) {
        basicComponent = Basic;
    } else if (sectionClass.indexOf('viome-modern__product-benefits') > -1) {
        basicComponent = Benefits;
    } else if (sectionClass.indexOf('clia-privacy') > -1) {
        basicComponent = CLIAPrivacy;
    } else if (sectionClass.indexOf('viome-terms') > -1) {
        basicComponent = Terms;
    } else if (sectionClass.indexOf('faqs') > -1) {
        basicComponent = FAQs;
    }
    return basicComponent;
}
