import React, { useContext } from 'react';
import { StyleContext } from 'utils/context/StyleContextProvider';
import classNames from 'classnames';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import TextRenderer from 'components/Text/Text';
import Hyperlink from '../Text/Hyperlink';
import InlineEntry from '../Text/InlineEntry';
import Paragraph from '../Text/Paragraph';
import Heading from '../Text/Heading';
import InlineBlock from '../Text/InlineBlock';
import Code from '../Text/Code';
import superscriptReferences from '../Text/superscriptReferences';
import layoutClasses from '../Utils/LayoutClasses';
import styles from './Text.module.scss';

const styleMap = {
    Centered: 'text-center',
    'Centered / Left @ Tablet': 'text-center md:text-left',
    'Centered / Left @ Desktop': 'text-center lg:text-left ',
    'Left Aligned': 'text-left',
    'Left Aligned / 60% @ Desktop': 'text-left lg:w-3/5',
    'Left / Centered / Left': 'text-left md:text-center lg:text-left',
    'Left / Centered @ Tablet': 'text-left md:text-center',
    'Left / Centered @ Desktop': 'text-left lg:text-center',
    'Left / Right @ Desktop': 'text-left lg:text-right',
    'Right Aligned': 'text-right',
    'Paris - List': styles.paris,
    HR: styles.hr,
};

export default function Text(props) {
    const { data, useP = false } = props,
        {
            title,
            body,
            fontSize,
            style,
            bodyTextScaling,
            verticalAlign,
            mobileHeaderFontSize,
            layout,
            borderColor,
            headerAsP,
        } = data,
        usePEl = headerAsP || useP,
        parentStyles = useContext(StyleContext),
        layoutOptions = layoutClasses(
            layout,
            borderColor,
            styles,
            null,
            fontSize,
        ),
        textStyle = styleMap[style] || 'text-center justify-center',
        options = {
            renderNode: {
                [BLOCKS.PARAGRAPH]: (node, nodeChildren) =>
                    Paragraph(
                        nodeChildren,
                        styles,
                        bodyTextScaling,
                        verticalAlign,
                        style,
                    ),
                [BLOCKS.HEADING_1]: (node, nodeChildren) =>
                    Heading(
                        styles,
                        node,
                        nodeChildren,
                        mobileHeaderFontSize,
                        bodyTextScaling,
                        usePEl,
                    ),
                [BLOCKS.HEADING_2]: (node, nodeChildren) =>
                    Heading(
                        styles,
                        node,
                        nodeChildren,
                        mobileHeaderFontSize,
                        bodyTextScaling,
                        usePEl,
                    ),
                [BLOCKS.HEADING_3]: (node, nodeChildren) =>
                    Heading(
                        styles,
                        node,
                        nodeChildren,
                        null,
                        bodyTextScaling,
                        usePEl,
                    ),
                [BLOCKS.HEADING_4]: (node, nodeChildren) =>
                    Heading(
                        styles,
                        node,
                        nodeChildren,
                        null,
                        bodyTextScaling,
                        usePEl,
                    ),
                [BLOCKS.HEADING_5]: (node, nodeChildren) =>
                    Heading(
                        styles,
                        node,
                        nodeChildren,
                        null,
                        bodyTextScaling,
                        usePEl,
                    ),
                [BLOCKS.HEADING_6]: (node, nodeChildren) =>
                    Heading(
                        styles,
                        node,
                        nodeChildren,
                        null,
                        bodyTextScaling,
                        usePEl,
                    ),
                [BLOCKS.EMBEDDED_ASSET]: (node) => InlineBlock(node, styles),
                [BLOCKS.EMBEDDED_ENTRY]: (node) => InlineBlock(node, styles),
                [INLINES.EMBEDDED_ENTRY]: (node) => InlineEntry(node, styles),
                [INLINES.HYPERLINK]: (node) => Hyperlink(node),
            },
            renderMark: {
                [MARKS.BOLD]: (text) => <strong>{text}</strong>,
                [MARKS.ITALIC]: (text) => <em>{text}</em>,
                [MARKS.SUPERSCRIPT]: (text) => superscriptReferences(text),
                [MARKS.CODE]: (text) => Code(text),
            },
            renderText: (txt) => TextRenderer(txt),
        };

    return (
        <div
            className={classNames(
                [styles.text, parentStyles?.text],
                textStyle,
                'text',
                layoutOptions.classes,
            )}
            style={layoutOptions.styles}
            {...props}
        >
            {title && (
                <p className={classNames(styles.title, 'heading', 'heading-2')}>
                    {title}
                </p>
            )}
            {body && documentToReactComponents(body, options)}
        </div>
    );
}
