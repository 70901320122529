import React, { useEffect, useRef, useContext } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import Router from 'next/router';
import { useDetectOutsideClick, cBoolean, parseURL } from '@viome/vnxt-nucleus';
import ConditionalWrapper from '../../utils/ConditionalWrapper';
import { StyleContext } from '../../utils/context/StyleContextProvider';

export default function NavItem({ linkData, nestedLinks }) {
    const styles = useContext(StyleContext),
        dropdownRef = useRef(null),
        [isActive, setIsActive] = useDetectOutsideClick(
            dropdownRef,
            false,
            false,
            true,
        ),
        toggleHandler = (e) => {
            setIsActive(!isActive);
            e.preventDefault();
        },
        toggleOff = () => setIsActive(false),
        hasNested = nestedLinks && nestedLinks.length > 0,
        isProduct = linkData?.title === 'Products',
        linkAttr = {
            'data-position': 'nav',
        };

    useEffect(() => {
        const handleRouteChange = () => {
            setIsActive(false);
        };

        Router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            Router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, []);

    let menuProps = {};
    if (hasNested) {
        menuProps = {
            onClick: toggleHandler,
            onMouseOver: toggleHandler,
            onFocus: toggleHandler,
            onKeyPress: toggleHandler,
            role: 'menuitem',
            tabIndex: 0,
        };
    }

    return (
        <li
            className={classNames(
                styles.viomeNav__item,
                hasNested && styles.click,
                linkData?.title === 'Products' ? styles.product : null,
            )}
            onMouseLeave={toggleOff}
        >
            <Link href={linkData?.url || '/'} {...menuProps}
                  className={classNames(
                        styles.viomeNav__link,
                        isActive && styles.active,
                    )}
                  {...linkAttr}>

                {linkData?.title}
            </Link>
            {hasNested && (
                <div
                    onMouseLeave={toggleOff}
                    ref={dropdownRef}
                    className={styles['viomeNav__dropdown-menu']}
                >
                    <ConditionalWrapper
                        condition={isProduct}
                        wrapper={(children) => (
                            <div className={styles.product__wrapper}>
                                {children}
                            </div>
                        )}
                    >
                        {nestedLinks.map((nestedLink, index) => {
                            const nestedLinkData =
                                nestedLink?.fields || nestedLink;
                            if (nestedLinkData) {
                                const isExternal = cBoolean(
                                        nestedLinkData?.isExternal,
                                    ),
                                    linkIcon =
                                        nestedLinkData?.icon?.fields ||
                                        nestedLinkData?.icon?.url ||
                                        null,
                                    linkProps = { ...linkAttr };

                                if (isExternal) {
                                    linkProps.target = '_blank';
                                    linkProps.rel = 'noopener external';
                                }
                                if (linkIcon) {
                                    linkProps.target = '_blank';
                                }

                                return (
                                    <React.Fragment
                                        key={
                                            nestedLinkData.title ||
                                            nestedLinkData.slug
                                        }
                                    >
                                        {nestedLinkData.url ? (
                                            <Link
                                                href={parseURL(
                                                    nestedLinkData.url,
                                                )}
                                                onClick={toggleOff}
                                                onKeyPress={toggleOff}
                                                role="menuitem"
                                                tabIndex={index}
                                                className={classNames(
                                                        styles.viomeNav__link,
                                                        styles[
                                                            'viomeNav__link--dropdown'
                                                        ],
                                                        linkIcon &&
                                                            styles[
                                                                'viomeNav__link--icon'
                                                            ],
                                                    )}
                                                {...linkProps}
                                            >

                                                {nestedLinkData.title}
                                                {linkIcon && (
                                                <img
                                                            src={
                                                                linkIcon?.file
                                                                    ?.url ||
                                                                linkIcon.url
                                                            }
                                                            alt={linkIcon.title}
                                                            type={
                                                                linkIcon?.file
                                                                    ?.contentType ||
                                                                linkIcon.contentType
                                                            }
                                                        />
                                                    )}
                                            </Link>
                                        ) : null}
                                    </React.Fragment>
                                );
                            }
                            return null;
                        })}
                    </ConditionalWrapper>
                    {isProduct && (
                        <div className={classNames(styles.product__button)}>
                            <Link href="/products/" {...linkAttr}>
                                Compare all products
                            </Link>
                        </div>
                    )}
                </div>
            )}
        </li>
    );
}
