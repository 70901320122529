import React, { useContext } from 'react';
import { formatPrice } from '@viome/vnxt-nucleus';
import { modalContext } from 'utils/context/DOMContextProvider';
import Popup from '../../Tailwind/Components/PopupGeneric';
import Text from '../../Tailwind/Module/Text';

export default function Billing({
    discount,
    price,
    productShopify,
    showTax,
    billingDetails,
}) {
    const { setModal } = useContext(modalContext),
        handleModal = () => {
            setModal(
                <Popup
                    closeState={() => {
                        setModal(null);
                    }}
                    centerPopup
                    useCTA
                >
                    <Text data={billingDetails.fields} />
                </Popup>,
            );
        };

    return (
        <p>
            ${formatPrice(productShopify?.price || price, discount)} today / $
            {formatPrice(productShopify?.price || price, discount)} when your
            first order and each subsequent order are prepared and shipped to
            you. {showTax && <span>Plus tax, if applicable.</span>}
            {billingDetails && (
                <a
                    onClick={handleModal}
                    onKeyUp={handleModal}
                    role="button"
                    tabIndex={0}
                >
                    See Details
                </a>
            )}
        </p>
    );
}
