import { useContext } from 'react';
import {
    parseModules,
    Breadcrumb,
    ConditionalWrapper,
} from '@viome/vnxt-nucleus';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import classNames from 'classnames';
import Accordion from 'components/Tailwind/Module/Accordion';
import Card from 'components/Tailwind/Module/Card';
import Carousel from 'components/Tailwind/Module/Carousel';
import CompareSection from 'components/Compare/CompareSection';
import HorizontalScroll from 'components/Tailwind/Module/HorizontalScroll';
import HTML from 'components/Layout/Module/HTML';
import Image from 'components/Layout/Module/Image';
import NewsArticle from 'components/Layout/Module/NewsArticle';
import PageNavigation from 'components/Tailwind/Module/PageNavigation';
import Person from 'components/Tailwind/Module/Person';
import ProductsBody from 'components/Tailwind/Module/ProductsBody';
import ProductHeader from 'components/Tailwind/Module/ProductsBody/ProductHeader';
import Publication from 'components/Layout/Module/Publication';
import Selector from 'components/Tailwind/Module/Selector';
import Study from 'components/Layout/Module/Study';
import Testimonial from 'components/Tailwind/Module/Testimonial';
import Guide from 'components/Tailwind/Module/Guide';
import Text from 'components/Tailwind/Module/Text';
import Video from 'components/Layout/Module/Video';
import PageSet from 'components/Layout/Module/PageSet';
import layoutClasses from 'components/Tailwind/Utils/LayoutClasses';
import GridDropdown from 'components/Tailwind/Module/GridDropdown';
import ProductSelectorV2 from 'components/Tailwind/Module/ProductSelectorV2';
import Row from 'components/Tailwind/Row';
import GlobalContext from 'utils/context/GlobalContextProvider';
import { ENVIRONMENT } from 'utils/constants';
import Grants from '../Misc/Grants';
import Careers from '../Misc/Careers';
import GlossaryControl from '../Glossary/GlossaryControl';
import GlossaryTermsIndex from '../Glossary/GlossaryTermsIndex';

export default function Modules({
    modules,
    styles = {},
    columns,
    darkMode,
    rowLayout = {},
    attrs = {},
}) {
    const modulesArray = [],
        length = (modules && modules.length) || 0,
        max = length,
        globalContext = useContext(GlobalContext);

    let layoutOptions = {
            styles: {},
        },
        moduleLayoutOptions = {};

    if (length > 0) {
        for (let i = 0; i <= max - 1 && i < length; i++) {
            const moduleFields = modules[i],
                id = moduleFields?.sys.id,
                moduleType = moduleFields.__typename;

            let moduleContent;

            if (moduleType === 'moduleImage') {
                moduleContent = (
                    <Image
                        data={moduleFields.src}
                        mp4={moduleFields.mp4}
                        options={{
                            align: moduleFields.align,
                            alt: moduleFields.alt,
                            caption: moduleFields.caption,
                            width: moduleFields.displayWidth,
                            widthMobile: moduleFields.maxWidthMobile,
                            widthTablet: moduleFields.maxWidthTablet,
                            layout: moduleFields.layout,
                            priority: moduleFields.priority,
                            quality: moduleFields.quality,
                            mobile: moduleFields.srcMobile,
                            tablet: moduleFields.srcTablet,
                            layoutOptions: moduleFields.layoutOptions,
                        }}
                    />
                );
                layoutOptions = layoutClasses(
                    moduleFields?.layoutOptions || {},
                    null,
                    styles,
                );
            } else if (moduleType === 'moduleText') {
                layoutOptions = layoutClasses(
                    moduleFields?.layout || {},
                    null,
                    styles,
                );

                moduleContent = <Text data={moduleFields} />;
            } else if (moduleType === 'video') {
                moduleContent = <Video data={moduleFields} />;
            } else if (moduleType === 'moduleAccordion') {
                moduleContent = (
                    <Accordion data={moduleFields} darkMode={darkMode} />
                );
                layoutOptions = layoutClasses(
                    moduleFields?.layout || {},
                    null,
                    styles,
                );
            } else if (moduleType === 'moduleCarousel') {
                if (moduleFields.carouselType === 'Solution-Gallery') {
                    layoutOptions.rowClasses = [styles.sticky];
                }
                moduleContent = <Carousel data={moduleFields} />;
            } else if (moduleType === 'variationWrapper') {
                const variant =
                    moduleFields.meta[globalContext.variant || 'Control'];

                moduleContent = (
                    <>
                        {ENVIRONMENT !== 'production' &&
                            ENVIRONMENT !== 'preview' && (
                                <div className="flex flex-row gap-10">
                                    <div>
                                        <b>Experiment:</b> <br />
                                        <b>Title</b>:{' '}
                                        {`${moduleFields.campaignTitle}`} <br />
                                        <b>Campaign Id</b>:{' '}
                                        {`${moduleFields.campaignId}`}
                                        <br />
                                        <b>Group:</b> {variant}
                                        <br />
                                        <b>User:</b> {globalContext.userID}
                                        <br /> <br />
                                    </div>
                                    <div>
                                        <b>Meta:</b>{' '}
                                        <pre>
                                            {JSON.stringify(
                                                moduleFields.meta,
                                                null,
                                                2,
                                            )}
                                        </pre>
                                    </div>
                                </div>
                            )}
                        <Modules
                            modules={parseModules([
                                moduleFields.variations[variant],
                            ])}
                        />
                    </>
                );
            } else if (moduleType === 'moduleHorizontalScroll') {
                moduleContent = (
                    <HorizontalScroll data={moduleFields} styles={styles} />
                );
            } else if (moduleType === 'moduleCard') {
                moduleContent = <Card data={moduleFields} />;
            } else if (moduleType === 'newsItem') {
                moduleContent = <NewsArticle data={moduleFields} />;
            } else if (moduleType === 'productsNavMenu') {
                moduleContent = <PageNavigation data={moduleFields} />;
            } else if (moduleType === 'publication') {
                moduleContent = <Publication data={moduleFields} />;
            } else if (moduleType === 'study') {
                moduleContent = <Study data={moduleFields} />;
            } else if (moduleType === 'author') {
                moduleContent = (
                    <Person
                        data={moduleFields}
                        columns={columns || 1}
                        layout={rowLayout.person}
                    />
                );
            } else if (moduleType === 'moduleHtml') {
                moduleContent = <HTML data={moduleFields} />;
            } else if (moduleType === 'moduleMisc') {
                const miscModuleType = moduleFields.moduleType,
                    miscModules = moduleFields.modules || [];

                if (miscModuleType === 'VLS - Grants Form') {
                    moduleContent = <Grants />;
                } else if (miscModuleType === 'Careers List') {
                    moduleContent = <Careers />;
                } else if (miscModuleType === 'Glossary - Control') {
                    moduleContent = <GlossaryControl />;
                } else if (miscModuleType === 'Glossary - Terms Index') {
                    moduleContent = <GlossaryTermsIndex />;
                } else if (miscModuleType === 'Guide') {
                    moduleContent = miscModules.map((module) => (
                        <Guide fields={module.fields} darkMode={darkMode} />
                    ));
                } else if (miscModuleType === 'FAQs') {
                    const faqsItems = [];
                    for (let j = 0; j <= miscModules.length - 1; j++) {
                        faqsItems.push(
                            <Card
                                data={miscModules[j].fields}
                                key={miscModules[j].fields.name}
                                type="faqsItem"
                            />,
                        );
                    }
                    moduleContent = faqsItems;
                } else if (miscModuleType.includes('Product Grid')) {
                    const parsedType = miscModuleType.split(' - '),
                        productsType = parsedType[1],
                        isBasic = parsedType[1].includes('Shop'),
                        isSection = parsedType[1].includes('Section');

                    moduleContent = (
                        <ProductsBody
                            modules={miscModules}
                            settings={moduleFields?.settings || {}}
                            basic={!isBasic}
                            isSection={isSection}
                            type={productsType}
                        />
                    );
                } else if (miscModuleType.includes('Compare')) {
                    moduleContent = <CompareSection products={miscModules} />;
                } else if (miscModuleType.includes('Breadcrumb')) {
                    const items = [];
                    moduleFields?.modules.forEach((item) => {
                        const itemUrl = item.fields.url,
                            itemText = item.fields.linkText;

                        items.push({
                            title: itemText,
                            url: itemUrl,
                            wrapper: (
                                <ConditionalWrapper
                                    condition={itemUrl}
                                    wrapper={(nodeChildData) => (
                                        <Link
                                            href={itemUrl}
                                            itemProp="item"
                                            itemScope
                                            itemType="https://schema.org/ListItem"
                                            itemID={itemUrl}
                                        >
                                            {nodeChildData}
                                        </Link>
                                    )}
                                >
                                    {itemText}
                                </ConditionalWrapper>
                            ),
                        });
                    });
                    moduleContent = (
                        <Breadcrumb
                            items={items}
                            settings={moduleFields?.settings || {}}
                        />
                    );
                }
            } else if (moduleType === 'moduleTestimonial') {
                moduleContent = <Testimonial data={moduleFields} />;
            } else if (moduleType === 'moduleProductSelector') {
                moduleContent = <ProductSelectorV2 data={moduleFields} />;
            } else if (moduleType === 'moduleSelector') {
                const miscModuleType = moduleFields.interactionType;

                if (miscModuleType === 'product-selector') {
                    // https://viomeinc.atlassian.net/browse/JNY-2152 : Viome A/B Pricing Test - Promos Displayed vs No Promos Displayed
                    const ProductSelector = dynamic(
                        import('components/Tailwind/Module/ProductSelector'),
                        {
                            ssr: false,
                        },
                    );

                    moduleContent = (
                        <ProductSelector
                            data={moduleFields}
                            settings={moduleFields?.settings || {}}
                        />
                    );
                } else if (miscModuleType.includes('grid')) {
                    moduleContent = (
                        <GridDropdown
                            data={moduleFields}
                            settings={moduleFields?.settings || {}}
                        />
                    );
                } else {
                    moduleContent = (
                        <Selector data={moduleFields} darkMode={darkMode} />
                    );
                }
            } else if (moduleType === 'modulePageSet') {
                moduleContent = <PageSet data={moduleFields} />;
            } else if (moduleType === 'product') {
                moduleContent = (
                    <ProductHeader
                        productFields={moduleFields}
                        styles={styles}
                        isModule
                    />
                );
            } else {
                moduleContent = <Row data={moduleFields} nested />;

                layoutOptions = layoutClasses(
                    moduleFields?.layout || {},
                    null,
                    styles,
                );
            }

            moduleLayoutOptions = layoutClasses(
                moduleFields?.layoutOptions?.module || {},
                null,
                styles,
            );

            attrs.style = { ...moduleLayoutOptions.styles };

            modulesArray.push(
                <div
                    className={classNames(
                        styles.modules,
                        moduleType && styles[moduleType],
                        moduleType && moduleType,
                        layoutOptions.rowClasses,
                        moduleLayoutOptions.classes,
                    )}
                    key={`${i}${id}`}
                    {...attrs}
                >
                    {moduleContent}
                </div>,
            );
        }

        return modulesArray;
    }
    return null;
}
