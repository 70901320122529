import Carousel from 'containers/Sections/Carousel';
import CarouselAuthors from 'containers/Sections/CarouselAuthors';
import CarouselFeatured from 'containers/Sections/CarouselFeatured';
import { cBoolean, getViewport } from '@viome/vnxt-nucleus';

function CarouselComponent(sectionClass) {
    let carouselComponent;

    if (sectionClass) {
        if (sectionClass.indexOf('author-quotes') > -1) {
            carouselComponent = CarouselAuthors;
        } else if (sectionClass.indexOf('featured-quotes') > -1) {
            carouselComponent = CarouselFeatured;
        }
    }
    return carouselComponent || Carousel;
}

function CarouselSettings(data, settings, sectionClass, width) {
    const additionalSettings = settings?.additionalSettings,
        additionalSettingsVP =
            additionalSettings && additionalSettings[getViewport(width)];

    let options =
        settings?.carousel?.options ||
        additionalSettingsVP ||
        additionalSettings;

    if (sectionClass) {
        if (
            sectionClass.indexOf('viome-modern__home__misc-recoded__carousel') >
                -1 ||
            sectionClass.indexOf('viome-modern__hi--decode-carousel') > -1 ||
            sectionClass.indexOf('viome-modern__hi--activate-carousel') > -1 ||
            sectionClass.indexOf('biological-age-carousel') > -1 ||
            sectionClass.indexOf('immunity-in-your-body-carousel') > -1 ||
            sectionClass.indexOf('science-media-quotes-carousel') > -1
        ) {
            options = {
                showArrows: true,
                ...(options || {}),
            };
        }
    } else {
        options = {
            autoPlay: cBoolean(data.autoplay),
            showArrows: cBoolean(data.showArrows),
            showIndicators: cBoolean(data.showIndicator),
            ...(options || {}),
        };
    }

    return options;
}

export { CarouselComponent, CarouselSettings };
