import classNames from 'classnames';
import Link from 'next/link';
import styles from './GlossaryTerm.module.scss';
import Text from '../Layout/Module/Text';

const _ = require('lodash');

const GlossaryTerm = ({ term, full = false }) => {
    const { title, referenceUrl } = term || {},
        displayReferenceUrl = (references) => (
            <ul className={classNames(styles.referenceUrl)}>
                {references.map((ref) => {
                    const referenceUrlKey = _.uniqueId('referenceUrl');
                    return (
                        <li key={referenceUrlKey}>
                            <Link href={ref} target="_blank" rel="noreferrer">
                                {ref}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        ),
        shortDescription = term?.shortDescription,
        descriptionJson = term?.description?.json,
        slug = term?.slug,
        readmoreTxt = 'Read more',
        pageURL = `/glossary/${slug}`;

    return (
        <div className={classNames(styles.GlossaryTerm__item)}>
            {!full && (
                <div className={classNames(styles.highlight)}>
                    <Link href={pageURL} className={classNames(styles.title)}>
                        <h3>{title}</h3>
                    </Link>
                    <p>{shortDescription}</p>
                    <Link href={pageURL} className={classNames(styles.readmore)}>
                        {readmoreTxt}
                    </Link>
                </div>
            )}
            {full && (
                <div className={classNames(styles.full)}>
                    <h2>{title}</h2>
                    <Text
                        data={{
                            body: descriptionJson,
                            style: 'Left Aligned - Full Width',
                        }}
                    />
                    {referenceUrl && displayReferenceUrl(referenceUrl)}
                </div>
            )}
        </div>
    );
};

export default GlossaryTerm;
