/* eslint-disable jsx-a11y/label-has-associated-control */
import { useContext, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { PromoContext } from 'utils/context/PromoContextProvider';
import ProductHeader from './ProductHeader';
import ProductDescription from './ProductDescription';
import ProductDetails from './ProductDetails';
import styles from './ProductsBody.module.scss';

export default function ProductsBody({
    modules,
    settings,
    basic,
    isSection,
    type,
}) {
    const products = {
            'Essential Bundles': {},
            'Personalized Health Products': {},
            'Health Tests': {},
        },
        gridSettings = settings.productGrid,
        gridDiscount = gridSettings?.discount || {},
        gridCode = gridSettings?.discountCode || {},
        gridMessage = gridSettings?.salesMessage,
        // https://viomeinc.atlassian.net/browse/JNY-2530: EXP008: Remove elements of Shop Page
        // https://docs.google.com/presentation/d/195WxojVB0ZKwT1m31vPYDk5n4bvdQoXj3W51nB_czxw/
        isVariant = type.includes('Shop B'),
        categoryKey = {
            All: {
                id: 'All',
                label: 'Shop All',
                products: [
                    'FCS',
                    'GHP',
                    'OHOL',
                    'OHP',
                    'FB',
                    'OHI',
                    'GI',
                    'HI',
                    'CD',
                    'BIOM',
                    'FPCD',
                    'OL',
                ],
            },
            Tests: {
                id: 'Tests',
                label: 'Tests',
                products: ['FB', 'OHI', 'GI', 'HI', 'CD'],
            },
            Bundles: {
                id: 'Bundles',
                label: 'Test + Health Solution Bundles',
                products: ['FCS', 'GHP', 'OHOL'],
            },
            Gut: {
                id: 'Gut',
                label: 'Gut Biotics',
                products: ['BIOM'],
            },
            Oral: {
                id: 'Oral',
                label: 'Oral Biotics',
                products: ['OL'],
            },
            Supplements: {
                id: 'Supplements',
                label: 'Supplements',
                products: ['FPCD'],
            },
            GH: {
                id: 'GH',
                label: 'Gut Health',
                products: ['GHP', 'GI'],
            },
            OH: {
                id: 'OH',
                label: 'Oral Health',
                products: ['OHOL', 'OHI'],
            },
            FB: {
                id: 'FB',
                label: 'Full Body Health & Longevity',
                products: ['FCS', 'FB'],
            },
            Health: {
                id: 'HEALTH',
                label: 'Personalized Health Products',
                products: ['BIOM', 'FPCD', 'OL'],
            },
        },
        sectionOrder = [
            'Health Tests',
            'Essential Bundles',
            'Personalized Health Products',
        ],
        // Ability to link directly to a category
        // #All || #Complete || #Stool || #Saliva
        router = useRouter(),
        { asPath } = router,
        // Match anything after # and before ?
        hashString = asPath.match(/#([^?]+)/),
        // Check if the hash value is a viable option
        hash = hashString && hashString[1] && categoryKey[hashString[1]],
        // Set hash value if valid, otherwise 'All'
        [active, setActive] = useState(hash || categoryKey.All),
        [filterMenu, setFilter] = useState(false),
        onClick = (filter) => {
            window.history.replaceState(
                {},
                filter.id,
                `${(hashString && hashString[0]) || asPath}#${filter.id}`,
            );
            setActive(filter);
        },
        filterClick = () => {
            setFilter(!filterMenu);
        },
        promoContext = useContext(PromoContext),
        hideProducts = promoContext.hideProducts || [];

    modules.forEach((module) => {
        const moduleType = module.sys.contentType.sys.id;

        if (moduleType === 'product') {
            const productFields = module.fields,
                {
                    name,
                    bundleDescription,
                    bundleUrl, // Temporarily remove bundle URL
                    sku,
                    slug,
                    shopifySubscriptionPlanProd,
                    shopifySubscriptionPlanStaging,
                    testType,
                } = productFields,
                isPlan =
                    shopifySubscriptionPlanProd ||
                    shopifySubscriptionPlanStaging,
                productURL = `/products/${slug}`;

            if (!hideProducts.includes(sku) && active?.products.includes(sku)) {
                const productsTestType = products[testType],
                    productMarkup = (
                        <div
                            className={classNames(styles.product, styles[sku])}
                            key={`${name}product`}
                        >
                            <Link href={bundleUrl || productURL}>
                                <ProductHeader
                                    productFields={productFields}
                                    isPlan={isPlan}
                                    isSection={isSection}
                                />
                            </Link>
                            <div className={styles.bottom}>
                                <ProductDetails
                                    product={module}
                                    productDiscount={gridDiscount[sku]}
                                    productCode={gridCode[sku]}
                                    salesMessage={gridMessage}
                                    styles={styles}
                                    isPlan={isPlan}
                                    isBasic={basic}
                                    isSection={isSection}
                                    testType={testType}
                                />
                                {!isSection && (
                                    <ProductDescription
                                        bundleDescription={bundleDescription}
                                        styles={styles}
                                    />
                                )}
                            </div>
                        </div>
                    );

                if (productsTestType) {
                    productsTestType[sku] = productMarkup;
                }
            }
        }
    });

    return (
        <div
            className={classNames(
                styles.shop,
                basic && styles.basic,
                isSection && styles.section,
                // https://viomeinc.atlassian.net/browse/JNY-2530: EXP008: Remove elements of Shop Page
                // https://docs.google.com/presentation/d/195WxojVB0ZKwT1m31vPYDk5n4bvdQoXj3W51nB_czxw/
                isVariant && styles.variant,
            )}
        >
            {!basic &&
                // https://viomeinc.atlassian.net/browse/JNY-2530: EXP008: Remove elements of Shop Page
                // https://docs.google.com/presentation/d/195WxojVB0ZKwT1m31vPYDk5n4bvdQoXj3W51nB_czxw/
                !isVariant && (
                    <>
                        <div
                            className={classNames(
                                styles.filter,
                                filterMenu && styles.active,
                            )}
                        >
                            <div
                                className={styles.plusToggle}
                                onClick={() => filterClick()}
                                onKeyPress={() => filterClick()}
                                role="button"
                                tabIndex="0"
                            >
                                Filters
                            </div>
                        </div>

                        <div
                            className={classNames(
                                styles.productSelectors,
                                `mb-10 md:mb-16 lg:mb-20 lg:flex`,
                            )}
                        >
                            {Object.keys(categoryKey).map((category) => {
                                const currentCategory = categoryKey[category];
                                return (
                                    <div
                                        onClick={() => onClick(currentCategory)}
                                        onKeyPress={() =>
                                            onClick(currentCategory)
                                        }
                                        role="button"
                                        tabIndex="0"
                                        className={classNames(
                                            styles.productSelector,
                                            active.id === category &&
                                                styles.active,
                                        )}
                                        key={`selector${category}`}
                                    >
                                        <p className={styles.productLabel}>
                                            {currentCategory.label}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}
            <div>
                {sectionOrder.map((productSection) => {
                    const currentProductSection = products[productSection],
                        sectionKeys = Object.keys(currentProductSection) || [];

                    if (sectionKeys.length > 0) {
                        return (
                            <>
                                {sectionKeys.length > 0 && !basic && (
                                    <p
                                        className={classNames(
                                            styles.sectionTitle,
                                        )}
                                    >
                                        {productSection === 'Essential Bundles'
                                            ? 'Health Solution Bundles'
                                            : productSection}
                                    </p>
                                )}
                                <div
                                    className={classNames(
                                        styles.grid,
                                        isSection
                                            ? modules.length < 4 && [
                                                  `lg:grid-cols-3`,
                                                  styles['section-3'],
                                              ]
                                            : `lg:grid-cols-3 lg:gap-x-8 lg:gap-y-20`,
                                        `grid gap-16 md:grid-cols-2 md:gap-x-4 md:gap-y-10`,
                                    )}
                                >
                                    {Object.keys(currentProductSection).map(
                                        (product) =>
                                            currentProductSection[product],
                                    )}
                                </div>
                            </>
                        );
                    }
                    return null;
                })}
            </div>
        </div>
    );
}
